<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">

        <v-form
          v-model="validForm"
          ref="form"
          lazy-validation
        >

          <v-text-field
            v-model="username"
            :rules="[rules.required]"
            label="Username"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            :rules="[rules.required]"
            label="Password"
            type="password"
            required
          ></v-text-field>

          <div class="mb-4 red--text text-caption" v-html="errMsg"></div>

          <v-btn
            block
            color="primary"
            @click="validate"
            :disabled="!validForm"
          >Login</v-btn>

        </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'AdminLogin',
  data: () => ({
    validForm: false,
    // loginError: false,
    username: null,
    password: null,
    rules: {
      required: value => !!value || 'Campo obrigatório.',
    },
    errMsg: "&nbsp;",
  }),
  methods: {
    validate() {
      const validation = this.$refs.form.validate();
      if(validation) this.submit();
    },
    submit() {
      const apiEndpoint = `${process.env.VUE_APP_API_URL}`;
      axios.post(apiEndpoint, { action: "login", username: this.username, password: this.password })
      .then(resp => resp.data)
      .then(data => {
        if(data.errors) {
          this.errMsg = data.errors[0];
          setTimeout(() => {
            this.errMsg = "&nbsp;";
          }, 5000);
        }

        this.$root.token = data.token;
        localStorage.setItem('grespRegistrations', JSON.stringify({token:data.token}));

        this.$router.push({ name: "AdminHome" });

      })
      .catch(err => {
        console.error(err);
      });
    },
  },
}

</script>